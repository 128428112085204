import { initIntercom } from '$lib/utils/chat/intercom'
import { initMixpanel } from '$lib/utils/tracking/mixpanel'
import type { HandleClientError } from '@sveltejs/kit'
import * as Sentry from '@sentry/svelte'
import {
  PUBLIC_ENVIRONMENT,
  PUBLIC_PUBLIC_SENTRY_DSN,
} from '$env/static/public'
import { version } from '$app/environment'
import { utils } from '$lib/utils'

initMixpanel()
initIntercom()

Sentry.init({
  dsn: PUBLIC_PUBLIC_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  environment: PUBLIC_ENVIRONMENT,
  enabled: window.location.hostname !== 'localhost',
  release: version,
  ignoreErrors: utils.SENTRY_IGNORE_ERRORS,
})

Sentry.setTag('public', 'browser')

export const handleError: HandleClientError = ({ error, event, status }) => {
  let errorMessage = 'Something went wrong'

  if (error) {
    if (typeof error === 'object') {
      if ('message' in error && typeof error.message === 'string') {
        errorMessage = error.message
      } else if (
        'body' in error &&
        typeof error.body === 'object' &&
        error.body &&
        'message' in error.body &&
        typeof error.body.message === 'string'
      ) {
        errorMessage = error.body.message
      } else if (
        'error' in error &&
        typeof error.error === 'object' &&
        error.error &&
        'message' in error.error &&
        typeof error.error.message === 'string'
      ) {
        errorMessage = error.error.message
      }
    } else if (typeof error === 'string') {
      errorMessage = error
    }
  }

  if (status !== 404) {
    Sentry.captureException(errorMessage, {
      contexts: { sveltekit: { event: JSON.stringify(event) } },
    })
  }

  return {
    user_message: 'Something went wrong',
    message: errorMessage,
    url: event.url.href,
  }
}
